import * as React from 'react'; 
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import './navigationBar.css';

export default function GroupTagMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <span className='menu-subheader' onClick={handleClick}>
        ID Tagging
      </span>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem sx={{fontSize: "15px"}} component={Link} to="/id-tagging/add" onClick={handleClose}>Add ID Tag</MenuItem>
        <MenuItem sx={{fontSize: "15px"}} component={Link} to="/id-tagging/view" onClick={handleClose}>View All ID Tags</MenuItem> 
      </Menu>
    </div>
  );
}