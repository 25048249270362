import React from 'react';
import AddKeywordForm from '../components/addKeywordForm.js'; 

const AddKeywordPage = (props) => {
  return (
    <>
        <h1 className='header'>
            Add Keywords
        </h1>
        <AddKeywordForm user={props.user}/>
    </>
    );
};

export default AddKeywordPage;