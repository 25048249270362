import React from 'react';
import AddFaceprintForm from '../components/addFaceprintForm.js'; 

const AddFaceprintPage = (props) => {
  return (
    <>
        <h1 className='header'>
            Add Faceprint
        </h1>
        <AddFaceprintForm user={props.user}/>
    </>
    );
};

export default AddFaceprintPage;