import React from 'react';
import GroupTagTable from "../components/groupTagTable.js";
import "./viewAllGroupTagPage.css";

const ViewAllGroupTagPage = () => {
    return <> 
        <h1 className='header' >
            ID Tagging
        </h1> 
        <div className='grouptag-table'>
            <GroupTagTable/>
        </div> 
    </>
};

export default ViewAllGroupTagPage;