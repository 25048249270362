import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'; 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';  
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper'; 
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { get, put } from 'aws-amplify/api';  
import { Link } from "react-router-dom";

const ERROR_MESSAGE = "Platform, tasking type and/or tasking value field(s) are empty!";
const SUCCESS_MESSAGE = "Successfully updated tasking. Activate entry ";  
const DELETE_MESSAGE = "Successfully deleted entry. Redirecting to previous page..."
const DELETE_FAILED_MESSAGE = "Something went wrong, please try again."
const platforms = [
    "Facebook", 
    "YouTube",
    "Instagram",
    "TikTok",
    "Twitter"
    ];
const tasking_type = [ 
    "Hashtag", 
    "Keyword",
    "Account"
    ];
    
export default function ModifyTaskingForm(props) {  
    const [platform, setPlatform] = useState(props.data.platform);  
    const [taskingType, setTaskingType] = useState(props.data.tasking_type);
    const [hashtag, setHashtag] = useState(props.data.hashtag); // If tasking type is 'Hashtag'
    const [keyword, setKeyword] = useState(props.data.keyword); // If tasking type is 'Keyword'
    const [accountID, setAccountID] = useState(props.data.account_id); // If tasking type is 'Activity'
    const [accountName, setAccountName] = useState(props.data.account_name); // If tasking type is 'Activity'
    const [accountURL, setAccountURL] = useState(props.data.account_url); // If tasking type is 'Activity' 
    const [accountUsername, setAccountUsername] = useState(props.data.account_username); // If tasking type is 'Activity' 
    const [status, setStatus] = useState(null); 
    const [openDialog, setOpenDialog] = useState(false);  
    const [openAlert, setOpenAlert] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false); 
    const [current, setCurrent] = useState(); 
    useEffect(() => { 
      const getCurrent = async () => {
        try {
          const getOperation = get({
            apiName: 'hcquotamapapi',
            path: '/hcquotamap'             
          });
          const { body } = await getOperation.response;
          const json = await body.json(); 
          let selectedCurrentList = json.find(list => list.team === (props.user.team + "-current"));
          setCurrent(selectedCurrentList);         
        } catch (err) {
          console.log('GET call failed: ', err);
        }
      }
      getCurrent();
    }, [props.user.team]);
    
    // For delete data, the "hashtag/keyword/account URL/Name/ID" fields will not be filled in
    const deleteData = async () => { 
        if (props.data.is_active) {
            let temp = {...current};
            temp[platform.toLowerCase() + "_" + taskingType.toLowerCase()]--;
            try {
               const putOperation = put({
                    apiName: 'hcquotamapapi',
                    path: '/hcquotamap',
                    options: {
                        body: {
                            team: props.user.team + "-current",
                            facebook_account: temp.facebook_account,
                            facebook_hashtag: temp.facebook_hashtag,
                            facebook_keyword: temp.facebook_keyword,
                            instagram_account: temp.instagram_account,
                            instagram_hashtag: temp.instagram_hashtag,
                            instagram_keyword: temp.instagram_keyword,
                            youtube_account: temp.youtube_account,
                            youtube_hashtag: temp.youtube_hashtag,
                            youtube_keyword: temp.youtube_keyword,
                            twitter_account: temp.twitter_account,
                            twitter_hashtag: temp.twitter_hashtag,
                            twitter_keyword: temp.twitter_keyword,
                            tiktok_account: temp.tiktok_account,
                            tiktok_hashtag: temp.tiktok_hashtag,
                            tiktok_keyword: temp.tiktok_keyword,  
                        }
                   }
                })
                await putOperation.response;                 
            } catch (err) {
                console.log('PUT call failed: ', err);
            }
        }
        
        try {
            const putOperation = put({
                apiName: 'hctaskingapi',
                path: '/hctasking',
                options: {
                    body: {
                        PK: props.data.PK,
                        platform: platform, 
                        tasking_type: taskingType,
                        tasking_value: props.data.tasking_value, 
                        creator: props.data.creator,
                        date_created: props.data.date_created, 
                        is_active: false,
                        date_modified: new Date().toLocaleString(),
                        latest_status: props.data.latest_status,
                        last_modifier: props.user.username, 
                        team: props.user.team, 
                        delete: true
                    }
                }
            })
            await putOperation.response; 
        } catch (err) { 
            console.log('PUT call failed: ', err);
        } 
    }
    
    const handleDelete = () => {
        setOpenDeleteDialog(true);
    }
    
    const handleConfirmDelete = () => {
        deleteData();
        setOpenDeleteDialog(false); 
        setOpenDeleteAlert(true); 
        setTimeout(() => window.location.replace("/social-media-tasking/manage-team"), 1500);
    }
    
    const handleOpen = () => {
        setOpenDialog(true);
    } 
    
    const handleClose = () => {
        setOpenDialog(false);
        setOpenDeleteDialog(false);
    } 
    
    const updateHashtagTasking = async () => {
        try {
            const putOperation = put({
                apiName: 'hctaskingapi',
                path: '/hctasking',
                options: {
                    body: {
                        PK: props.data.PK,
                        platform: platform, 
                        tasking_type: taskingType,
                        tasking_value: hashtag, 
                        hashtag: hashtag,
                        creator: props.data.creator,
                        date_created: props.data.date_created, 
                        is_active: props.data.is_active,
                        date_modified: new Date().toLocaleString(),
                        latest_status: props.data.latest_status,
                        last_modifier: props.user.username,
                        account_name: 'N.A.',
                        team: props.user.team, 
                        delete: false
                    }
                }
            })
            await putOperation.response; 
        } catch (err) {
            setStatus(false); // Different error message needed
            console.log('PUT call failed: ', err);
        }
    } 
    
    const updateKeywordTasking = async () => {
        try {
            const putOperation = put({
                apiName: 'hctaskingapi',
                path: '/hctasking',
                options: {
                    body: {
                        PK: props.data.PK,
                        platform: platform, 
                        tasking_type: taskingType,
                        tasking_value: keyword, 
                        keyword: keyword,
                        creator: props.data.creator,
                        date_created: props.data.date_created, 
                        is_active: props.data.is_active,
                        date_modified: new Date().toLocaleString(),
                        latest_status: props.data.latest_status,
                        last_modifier: props.user.username,
                        account_name: 'N.A.',
                        team: props.user.team, 
                        delete: false
                    }
                }
            })
            await putOperation.response; 
        } catch (err) {
            setStatus(false); // Different error message needed
            console.log('PUT call failed: ', err);
        }
    }
    
    const updateAccountTasking = async () => {
        try {
            const putOperation = put({
                apiName: 'hctaskingapi',
                path: '/hctasking',
                options: {
                    body: {
                        PK: props.data.PK,
                        platform: platform, 
                        tasking_type: taskingType,
                        tasking_value: accountID + "," + accountName + "," + accountUsername + "," + accountURL, 
                        account_id: accountID,
                        account_name: accountName,
                        account_url: accountURL,
                        account_username: accountUsername,
                        creator: props.data.creator,
                        date_created: props.data.date_created, 
                        is_active: props.data.is_active,
                        date_modified: new Date().toLocaleString(),
                        latest_status: props.data.latest_status,
                        last_modifier: props.user.username,
                        team: props.user.team, 
                        delete: false
                    }
                }
            })
            await putOperation.response;  
        } catch (err) {
            setStatus(false); // Different error message needed
            console.log('PUT call failed: ', err);
        }
    }
    
    const handleConfirm = () => {
        if (platform === '' || taskingType ==='') {
            setStatus(false);
            setOpenAlert(true);
        } else if ((taskingType === 'Hashtag' && hashtag.trim() === '') || 
        (taskingType === 'Keyword' && keyword.trim() === '') || 
        (taskingType === 'Account' && (accountID.trim() === '' || accountName.trim() === '' || accountURL.trim() === '' || accountUsername.trim() === ''))) {
            setStatus(false);
            setOpenAlert(true);     
        } else {  
            setStatus(true);
            setOpenAlert(true);  
            if (taskingType === 'Hashtag') {
                updateHashtagTasking(); 
            } else if (taskingType === 'Keyword') {
                updateKeywordTasking();
            } else if (taskingType === 'Account') {
                updateAccountTasking(); 
            } else {
                console.log('Error: Tasking type does not exist.');
            }            
        } 
        setOpenDialog(false);   
    } 
     
    let alert; 
    if (status) {
        alert = <Alert sx={{fontSize: '16px'}} severity="success" onClose={() => setOpenAlert(false)}>{SUCCESS_MESSAGE}<Link to="/social-media-tasking/manage-team">here</Link>.</Alert>; 
    } else if (status === null) {
        alert = <div></div>;
    } else {
        alert = <Alert sx={{ fontSize: '16px' }} severity="error" onClose={() => setOpenAlert(false)}>{ERROR_MESSAGE}</Alert>;
    }
    
    let redirect;
    if (openDeleteAlert) {
        redirect = <Alert sx={{fontSize: '16px'}} severity="success" onClose={() => setOpenDeleteAlert(false)}>{DELETE_MESSAGE}</Alert>; 
    } else if (openDeleteAlert === null) {
        redirect = <div></div>;
    } else {
        redirect = <Alert sx={{ fontSize: '16px' }} severity="error" onClose={() => setOpenDeleteAlert(false)}>{DELETE_FAILED_MESSAGE}</Alert>;
    }    
    
    return (
        <Box className='table-position'> 
            <React.Fragment> 
              <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please ensure that this record <strong>does not</strong> contain any code words.<br/>Are you sure you want to modify entry?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" className='dialog-button' onClick={handleConfirm}>
                        <span className='dialog-button-text'>
                            CONFIRM
                        </span> 
                    </Button>
                    <Button variant="outlined" className='dialog-button' onClick={handleClose} autoFocus>
                        <span className='dialog-button-text'>
                            CANCEL
                        </span> 
                    </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
            <React.Fragment> 
              <Dialog
                open={openDeleteDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete entry?<br/>This action <strong>cannot be undone</strong>.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" className='dialog-button' onClick={handleConfirmDelete}>
                        <span className='dialog-button-text'>
                            DELETE
                        </span> 
                    </Button>
                    <Button variant="outlined" className='dialog-button' onClick={handleClose} autoFocus>
                        <span className='dialog-button-text'>
                            CANCEL
                        </span> 
                    </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
            <Paper elevation={0} className='table'> 
                <Box> 
                    <div className='table-header'>
                        Platform
                    </div>
                    <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>
                        <FormControl fullWidth>
                            <InputLabel size="small">Select a platform</InputLabel>
                            <Select  
                              value={platform}
                              label="Select a platform"
                              size="small"
                              onChange={(event) => setPlatform(event.target.value)}
                            >
                            {platforms.map(
                                (type) => ( 
                                    <MenuItem value={type}>{type}</MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Box>
                    <div className='table-header'>
                        Tasking Type
                    </div>
                    <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>
                        <FormControl fullWidth>
                            <InputLabel size="small">Select a tasking type</InputLabel>
                            <Select  
                              value={taskingType}
                              label="Select a tasking type"
                              size="small"
                              onChange={(event) => setTaskingType(event.target.value)}
                            >
                            {tasking_type.map(
                                (type) => ( 
                                    <MenuItem value={type}>{type}</MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Box>
                    {(taskingType === 'Hashtag') ?
                        <><div className='table-header'>
                            Tasking Value
                        </div> 
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                            <FormControl fullWidth> 
                                <TextField 
                                    value={hashtag}
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    label="Hashtag"
                                    onChange={(event) => setHashtag(event.target.value)}
                                />     
                            </FormControl>
                        </Box> </>:
                        <div></div> 
                    }
                    {(taskingType === 'Keyword') ?
                        <><div className='table-header'>
                            Tasking Value
                        </div> 
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                            <FormControl fullWidth>                          
                                <TextField 
                                    value={keyword}
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    label="Keyword"
                                    onChange={(event) => setKeyword(event.target.value)}
                                />     
                            </FormControl>
                        </Box> </>:
                        <div></div> 
                    }
                    {(taskingType === 'Account') ?
                        <><div className='table-header'>
                            Tasking Value
                        </div> 
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                            <FormControl fullWidth> 
                                <TextField 
                                    value={accountID}
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    label="Account ID"
                                    onChange={(event) => setAccountID(event.target.value)}
                                />     
                            </FormControl>
                            <FormControl fullWidth sx={{paddingTop: '0.7rem'}}> 
                                <TextField 
                                    value={accountName}
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    label="Account Name"
                                    onChange={(event) => setAccountName(event.target.value)}
                                />     
                            </FormControl>
                            <FormControl fullWidth sx={{paddingTop: '0.7rem'}}> 
                                <TextField 
                                    value={accountUsername}
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    label="Account Username"
                                    onChange={(event) => setAccountUsername(event.target.value)}
                                />     
                            </FormControl>
                            <FormControl fullWidth sx={{paddingTop: '0.7rem'}}> 
                                <TextField 
                                    value={accountURL}
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    label="Account URL"
                                    onChange={(event) => setAccountURL(event.target.value)}
                                />     
                            </FormControl>
                        </Box> </>:
                        <div></div> 
                    }
                </Box>
                <Box className='button-box-position'>
                    <div className='button-position'>
                        <Button className='form-button' onClick={handleOpen} variant="contained">
                            <span className='button-text'>
                                UPDATE
                            </span> 
                        </Button>
                        <Button className='form-button' onClick={handleDelete} variant="contained">
                            <span className='button-text'>
                                DELETE
                            </span>  
                        </Button>
                    </div>
                </Box> 
            </Paper>   
            <Box>
                <div className='button-position'>
                    <Button sx={{ marginBottom:'1rem'}} className='form-button' variant="contained" component={Link} to="/social-media-tasking/manage-team" startIcon={<ArrowBackIcon />}>
                        <span className='button-text'>
                            BACK
                        </span> 
                    </Button> 
                </div>
            </Box>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openDeleteAlert}> 
                {redirect} 
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openAlert}> 
                {alert} 
            </Snackbar>  
        </Box>
    );
}
 