import React, { useState, useEffect }from 'react';
import TaskingDetailCard from '../components/taskingDetailCard.js';
import ModifyTaskingForm from '../components/modifyTaskingForm.js';
import CircularProgress from '@mui/material/CircularProgress'; 
import { get } from 'aws-amplify/api'; 
// import './modifyTaskingPage.css';

const ModifyTaskingPage = (props) => { 
    const path = window.location.pathname;
    const pk = path.split("/")[3];   
    const [data, setData] = useState([]);
    
    useEffect(() => {
      const getTasking = async () => {
        try {
          const getOperation = get({ 
          apiName: 'hctaskingapi',
          path: '/hctasking' 
        });
          const { body } = await getOperation.response;
          const json = await body.json(); 
          const temp = json.filter(function(x) {
            return (!x["delete"] && x["PK"] === pk); 
          }); 
          setData(temp[0]); 
          // console.log('GET call succeeded: ', json); 
        } catch (error) {
          console.log('GET call failed: ', error);
        }
      }
      getTasking(); 
    }, [pk]) 
     
    if (data.length === 0) {
        return <div className="loader"><CircularProgress sx={{ marginTop: '3rem' }} color="inherit"/></div>
    }  
    
    return (
        <> 
            <div className='detail-card'>
                <TaskingDetailCard data={data}/>
            </div>
            <div sx={{ marginBottom: "2rem"}}>
              <ModifyTaskingForm user={props.user} data={data}/>
            </div>            
        </>
    );
};

export default ModifyTaskingPage;

