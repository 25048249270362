import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress'; 
import InputAdornment from '@mui/material/InputAdornment';
import ImageList from '@mui/material/ImageList'; 
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField'; 
import { getUrl } from 'aws-amplify/storage'; 
import { get } from 'aws-amplify/api'; 
import { Pagination } from "@mui/material";
import FaceprintCard from './faceprintCard.js';
import './faceprintList.css';
export default function FaceprintList(props) {  
    const [data, setData] = useState([]); 
    const [filtered, setFiltered] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage] = useState(20);  
    
    useEffect(() => {
      const getUrlResult = async (path) => {
        try { 
          const url = await getUrl({
            key: path,
            options: {
              accessLevel: 'public',  
              validateObjectExistence: false,  
            },
          })  
          return url.url.toString();
        } catch (err) {
          console.log(err);
        }
      } 
        
      const getData = async () => {
        try {
            const getOperation = get({
                apiName: 'hcfaceprintapi',
                path: '/hcfaceprint'
            });
            const { body } = await getOperation.response; 
            const json = await body.json();
            const temp = [];
            const filtered_json = json.filter((x) => !x.delete && (x.restricted === 'everyone' || x.creator_team === props.user.team));
            // console.log("filtered: ", filtered_json); 
            filtered_json.forEach(x => {  
              const first_path = Object.keys(x.s3_hash_map)[0];
              temp.push(getUrlResult(first_path));  
              // getUrlResult(first_path);  
            })
            Promise.all(temp).then((values) => {
              const data = [];
              for (let  i = 0; i < filtered_json.length; i++) { 
                data.push({
                  url: values[i],
                  name: filtered_json[i].name,
                  categories: filtered_json[i].categories.toString(),
                  pk: filtered_json[i].PK,
                  status: filtered_json[i].status, // Status of entry
                  enable: filtered_json[i].enable
                }) 
              }  
              setData(data);
              setFiltered(data);
              setLoading(false); 
            }); 
            } catch (error) {
                console.log('GET call failed: ', error);
            }
        } 
        getData(); 
    }, [currentPage, props.user.team]);  

    // Search logic (PK, name or categories)
    useEffect(() => {  
      var temp = [];
      if (searchQuery === "") {
        temp = data;
      } else {  
        temp = data.filter((obj) => JSON.stringify(obj).toLowerCase().includes(searchQuery.toLowerCase()));  
        setCurrentPage(1);
        // console.log("Data search: ", temp);
      } 
      setFiltered(temp); 
    }, [searchQuery, data]);
      
    // Pagination logic  
    const indexOfLastCard = currentPage * itemPerPage;
    const indexOfFirstCard = indexOfLastCard - itemPerPage;
    const currentItems = filtered.slice(indexOfFirstCard, indexOfLastCard);
    const count = Math.ceil(filtered.length / itemPerPage); 
    const handlePageChange = (e, value) => {
      setCurrentPage(value);
    }
    
    if (loading) {
      return <div className="loader"><CircularProgress color="inherit"/></div>
    }
    
    return ( 
      <>
        <div className='search-bar-position'>
          <Box sx={{width: "30rem"}}> 
            <TextField 
              fullWidth 
              size="small"  
              className='search-bar'
              placeholder="Search..."
              autoComplete='off'
              onInput={(e) => { setSearchQuery(e.target.value); }} 
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button className='form-button' variant="contained" component={Link} to="/faceprint-mgt/add" startIcon={<AddIcon />}>
              <span className='button-text'>
                ADD FACEPRINT
              </span>  
          </Button>
        </div>   
        {currentItems.length === 0 ?  
          <div>
            No results found. 
          </div>
          :  
         <Box sx={{ width: '100%'}}>
          <ImageList cols={4} >
            {currentItems.map((item) => (
              <FaceprintCard item={item}/>
            ))}
          </ImageList>
        </Box> 
        } 
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "3rem", marginBottom: "3rem" }}> 
          <Pagination count={count} page={currentPage} onChange={handlePageChange}/>
        </Box>
      </>
      );
    }
 