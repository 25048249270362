import React, { useState, useEffect, useMemo } from 'react';
import { get, put } from 'aws-amplify/api'; 
import { Link } from 'react-router-dom';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';  
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';  
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip'; 
import Switch from '@mui/material/Switch';
import FilterListIcon from '@mui/icons-material/FilterList'; 
import { SnackbarProvider, useSnackbar } from 'notistack';
import './manageTaskingTable.css'; 
 
const columns = [
  { id: 'pk', 
    label: 'Ref. #', 
    align: 'left', 
    sortable: true,
  }, 
  { id: 'platform', 
    label: 'Platform', 
    align: 'left', 
    sortable: true,
  },
  { id: 'tasking_type', 
    label: 'Tasking Type', 
    align: 'left', 
    sortable: true,
  },
  { id: 'account_name', 
    label: 'Account Name', 
    align: 'left', 
    sortable: true,
  },   
  { id: 'tasking_value', 
    label: 'Tasking Value', 
    align: 'left', 
    sortable: false,
  }, 
  { id: 'creator', 
    label: 'Creator', 
    align: 'left', 
    sortable: true,
  },
  { id: 'latest_status', 
    label: 'Latest Status', 
    align: 'left', 
    sortable: false,
  },
  { id: 'is_active', 
    label: 'Activation', 
    align: 'left', 
    sortable: false,
  }, 
];

const platforms = [
  "Facebook",
  "Instagram",
  "YouTube",
  "TikTok",
  "Twitter"
] 
   
const tasking_types = [ 
    "Hashtag", 
    "Keyword",
    "Account"
    ];  
    
const status = [
    true,
    false
  ]
  
function createData(pk, platform, tasking_type, account_name, tasking_value, creator, latest_status, is_active, date) {
    return { pk, platform, tasking_type, account_name, tasking_value, creator, latest_status, is_active, date };
}  

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'date') { 
    return (new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf());
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {  order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow> 
        {columns.map((column) => (
          <TableCell
            key={column.id}
            sx={{ fontSize: '16px'}} 
            align={column.align}
            padding='normal'
            sortDirection={orderBy === column.id ? order : false}
          > 
          {column.sortable ? 
            <TableSortLabel 
              active={orderBy === column.id} 
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)} 
            >
              <div className='table-cell'>
                {column.label}
              </div>
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> :  
            <div className='table-cell'>
              {column.label}
            </div>
          } 
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = { 
  onRequestSort: PropTypes.func.isRequired, 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};  

function TeamTaskingTable(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]); 
    const [data, setData] = useState([]); 
    const [loading, setLoading] = useState(true); 
    const [searchQuery, setSearchQuery] = useState('');
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('date');
    const [totalActivated, setTotalActivated] = useState(0);
    const [displayedActivated, setDisplayedActivated] = useState(0);
    const [checked, setChecked] = useState({}); // Key-value pairs of respective PK and activation status  
    const { enqueueSnackbar } = useSnackbar(); 
    const [isDisabled, setIsDisabled] = useState(false); 
    // Filter default shows all. Filter will only be applied when clicked on "Apply" 
    const [selectedPlatformFilter, setSelectedPlatformFilter] = useState([]);
    const [selectedTaskingTypeFilter, setSelectedTaskingTypeFilter] = useState([]);
    const [selectedActivationFilter, setSelectedActivationFilter] = useState([]);
    const [platformFilter, setPlatformFilter] = useState([]);
    const [taskingTypeFilter, setTaskingTypeFilter] = useState([]);
    const [activationFilter, setActivationFilter] = useState([]);
    const [quota, setQuota] = useState();
    const [current, setCurrent] = useState();
    const [anchorEl, setAnchorEl] = React.useState(null);  
    const openFilter = Boolean(anchorEl); 
    
    const handleOpenFilter = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleCloseFilter = () => {
      setAnchorEl(null); 
    }; 
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    useEffect(() => { 
      const getCurrent = async () => {
        try {
          const getOperation = get({
            apiName: 'hcquotamapapi',
            path: '/hcquotamap'             
          });
          const { body } = await getOperation.response;
          const json = await body.json(); 
          let selectedCurrentList = json.find(list => list.team === (props.user.team + "-current"));
          // console.log('selectedCurrentList: ', selectedCurrentList);
          setCurrent(selectedCurrentList);         
        } catch (err) {
          console.log('GET call failed: ', err);
        }
      } 
      // New 'getCurrent' method to sync: Pulls data from table --> Filter --> Count
      // const tryCurrent = async () => {
      //   try {
      //     const getOperation = get({
      //       apiName: 'hctaskingapi',
      //       path: '/hctasking'
      //     });
      //     const { body } = await getOperation.response;
      //     const json = await body.json();
      //     let activated_facebook_account = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'facebook' && tasking.tasking_type === 'Account' && tasking.is_active);
      //     let activated_facebook_hashtag = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'facebook' && tasking.tasking_type === 'Hashtag' && tasking.is_active);
      //     let activated_facebook_keyword = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'facebook' && tasking.tasking_type === 'Keyword' && tasking.is_active);
      //     let activated_twitter_account = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'twitter' && tasking.tasking_type === 'Account' && tasking.is_active);
      //     let activated_twitter_hashtag = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'twitter' && tasking.tasking_type === 'Hashtag' && tasking.is_active);
      //     let activated_twitter_keyword = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'twitter' && tasking.tasking_type === 'Keyword' && tasking.is_active);
      //     let activated_instagram_account = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'instagram' && tasking.tasking_type === 'Account' && tasking.is_active);
      //     let activated_instagram_hashtag = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'instagram' && tasking.tasking_type === 'Hashtag' && tasking.is_active);
      //     let activated_instagram_keyword = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'instagram' && tasking.tasking_type === 'Keyword' && tasking.is_active);
      //     let activated_youtube_account = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'youtube' && tasking.tasking_type === 'Account' && tasking.is_active);
      //     let activated_youtube_hashtag = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'youtube' && tasking.tasking_type === 'Hashtag' && tasking.is_active);
      //     let activated_youtube_keyword = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'youtube' && tasking.tasking_type === 'Keyword' && tasking.is_active);
      //     let activated_tiktok_account = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'tiktok' && tasking.tasking_type === 'Account' && tasking.is_active);
      //     let activated_tiktok_hashtag = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'tiktok' && tasking.tasking_type === 'Hashtag' && tasking.is_active);
      //     let activated_tiktok_keyword = json.filter((tasking) => tasking.team === props.user.team && tasking.platform.toLowerCase() === 'tiktok' && tasking.tasking_type === 'Keyword' && tasking.is_active);
      //     let currentList = {
      //       facebook_account: activated_facebook_account.length,
      //       facebook_hashtag: activated_facebook_hashtag.length,
      //       facebook_keyword: activated_facebook_keyword.length,
      //       twitter_account: activated_twitter_account.length,
      //       twitter_hashtag: activated_twitter_hashtag.length,
      //       twitter_keyword: activated_twitter_keyword.length,
      //       instagram_account: activated_instagram_account.length,
      //       instagram_hashtag: activated_instagram_hashtag.length,
      //       instagram_keyword: activated_instagram_keyword.length,
      //       youtube_account: activated_youtube_account.length,
      //       youtube_hashtag: activated_youtube_hashtag.length,
      //       youtube_keyword: activated_youtube_keyword.length,
      //       tiktok_account: activated_tiktok_account.length,
      //       tiktok_hashtag: activated_tiktok_hashtag.length,
      //       tiktok_keyword: activated_tiktok_keyword.length,
      //       team: props.user.team
      //     } 
      //     // console.log('current: ', currentList);
      //     setCurrent(currentList);  
      //     // console.log("x", activated_facebook_account.length);
      //   } catch (err) {
      //     console.log('GET call failed: ', err);
      //   }
      // } 
      getCurrent();
      // tryCurrent();
    }, [totalActivated, props.user.team]);
    
    useEffect(() => {    
      const getData = async () => {
        try {
          const getOperation = get({ 
            apiName: 'hctaskingapi',
            path: '/hctasking' 
          });
          const { body } = await getOperation.response;
          const json = await body.json(); 
          const temp = [];
          // const tempJson = [];
          const activation_map = {};
          var activated = 0;  
          json.forEach(x => {
            if (x.delete) {
              return; // Skip item
            } else {
              if (x.team === props.user.team) {
                temp.push(createData(x.PK, x.platform, x.tasking_type, x.account_name, x.tasking_value, x.creator, x.latest_status, x.is_active, Date.parse(x.date_created)));
                // tempJson.push(x);
                if (x.is_active) {
                  activated++
                }
                activation_map[x.PK] = x.is_active; 
              } else {
                return; // Skip item
              }  
            } 
          }) 
          setChecked(activation_map);
          setTotalActivated(activated); 
          setDisplayedActivated(activated);  
          setData(temp);
          if (props.quota !== undefined) { 
            setQuota(props.quota);   
            setLoading(false);
          }  
        } catch (error) {
          console.log('GET call failed: ', error);
        }
      } 
      getData();   
    }, [totalActivated, props])  

    useEffect(() => {  
      const filterEmpty = () => {
        return (platformFilter.length === 0 && taskingTypeFilter.length === 0 && activationFilter.length === 0);
      } 
      
      var temp = [];       
      if (searchQuery === "") {
        temp = data; 
      } else {  
        temp = data.filter((obj) => JSON.stringify(obj).toLowerCase().includes(searchQuery.toLowerCase())); 
      }  
      
      const filtered = temp.filter((x) => {
        if (filterEmpty()) { // Default state
          return true;
        } else {
          return platformFilter.includes(x.platform) || taskingTypeFilter.includes(x.tasking_type) || activationFilter.includes(x.is_active);
        } 
      })
      
      var activated = 0;
      filtered.forEach(x => { if (x.is_active) {activated++;}} );
      setDisplayedActivated(activated); 
      setRows(filtered);
      setAnchorEl(null);    
      setPage(0); 
    }, [searchQuery, data, totalActivated, platformFilter, taskingTypeFilter, activationFilter]); 
    
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }; 
    
    const visibleRows = useMemo(() =>
        stableSort(rows, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        ),
      [rows, order, orderBy, page, rowsPerPage],
    );

    const modifyActivation = async (row) => { 
        try { 
          const putOperation = put({
            apiName: 'hctaskingapi',
            path: '/hctasking',
            // IMPORTANT: Modifying only the 'is_active' attribute, but need to include ALL fields in the DynamoDB table
            options: {
              body: {
                PK: row['PK'],
                platform: row['platform'],
                tasking_type: row['tasking_type'],
                tasking_value: row['tasking_value'],
                keyword: row['keyword'],
                is_active: row['is_active'],
                hashtag: row['hashtag'],
                account_id: row['account_id'],
                account_name: row['account_name'],
                account_url: row['account_url'],
                account_username: row['account_username'],
                creator: row['creator'],
                date_created: row['date_created'], 
                date_modified: row['date_modified'],
                last_modifier: row['last_modifier'],
                latest_status: row['latest_status'],
                team: row['team'],
                delete: false
              }
            }
          })
          await putOperation.response;  
          if (row['is_active']) {  
            setTotalActivated(totalActivated + 1); // This will trigger getOperation to pull the updated data from DynamoDB database  
            enqueueSnackbar('Successfully activated entry with Ref. #' + row['PK'], {variant: 'success', autoHideDuration: 5000, anchorOrigin: { horizontal: "center", vertical: "bottom" }});
          } else {
            setTotalActivated(totalActivated - 1); // This will trigger getOperation to pull the updated data from DynamoDB database 
            enqueueSnackbar('Successfully de-activated entry with Ref. #' + row['PK'], {variant: 'success', autoHideDuration: 5000, anchorOrigin: { horizontal: "center", vertical: "bottom" }});
          } 
        } catch (err) {
          enqueueSnackbar('Something went wrong. Please try again.', {variant: 'error', autoHideDuration: 5000, anchorOrigin: { horizontal: "center", vertical: "bottom" }});
          console.log('PUT call failed: ', err);
        } 
    }
    
    const modifyQuotaMap = async (current, team) => {
      try {
        const putOperation = put({
          apiName: 'hcquotamapapi',
          path: '/hcquotamap',
          options: {
            body: {
              team: team,
              facebook_account: current.facebook_account,
              facebook_hashtag: current.facebook_hashtag,
              facebook_keyword: current.facebook_keyword,
              instagram_account: current.instagram_account,
              instagram_hashtag: current.instagram_hashtag,
              instagram_keyword: current.instagram_keyword,
              youtube_account: current.youtube_account,
              youtube_hashtag: current.youtube_hashtag,
              youtube_keyword: current.youtube_keyword,
              twitter_account: current.twitter_account,
              twitter_hashtag: current.twitter_hashtag,
              twitter_keyword: current.twitter_keyword,
              tiktok_account: current.tiktok_account,
              tiktok_hashtag: current.tiktok_hashtag,
              tiktok_keyword: current.tiktok_keyword,              
            }
          }
        })
        await putOperation.response;
        // console.log('PUT call succeeded: ', response); 
      } catch (err) {
        console.log('PUT call failed: ', err);
      } 
    }
    
    // TODO: Handle quota logic here 
    const handleActivation = (pk, platform, tasking_type) => async (event) => {   
      // DO NOT REMOVE: Need to use spread here instead of temp = checked, so that a state change (different object, not pointing to the same object) will be detected to trigger a re-render of the React component.
      let temp = {...checked}; 
      let curr_temp = {...current};
      let str = platform.toLowerCase() + "_" + tasking_type.toLowerCase();
      let max = quota[str];
      let curr = current[str];  
      if (event.target.checked && (curr + 1) > max) {
        enqueueSnackbar('You have hit the activation quota limit.', {variant: 'error', autoHideDuration: 5000, anchorOrigin: { horizontal: "center", vertical: "bottom" }}); 
      } else {
        temp[pk] = event.target.checked;  
        setChecked(temp);   
        let row;  
        try {
            const getOperation = get({ 
              apiName: 'hctaskingapi',
              path: '/hctasking' 
            });
            const { body } = await getOperation.response;
            const json = await body.json();
            json.forEach((obj) => {
              if (obj.PK === pk) {
                row = obj;
              } 
            })
        } catch (error) {
          console.log('GET call failed: ', error);
        }  
        row["is_active"] = event.target.checked;  
        if (event.target.checked) {
          curr_temp[str]++; 
        } else { 
          curr_temp[str]--;
        } 
        // console.log("curr_temp: ", curr_temp);
        setIsDisabled(true, setTimeout(() => { setIsDisabled(false) }, 1000));
        modifyActivation(row); 
        modifyQuotaMap(curr_temp, props.user.team + "-current");        
      } 
    } 
    
    const updateFilter = (checked, category, filter) => {
      if (checked) {
        if (category === "Platforms") {
          setSelectedPlatformFilter((previous) => Array.from(new Set(previous).add(filter)));
        } 
        if (category === "Tasking Types") {
          setSelectedTaskingTypeFilter((previous) => Array.from(new Set(previous).add(filter)));
        } 
        if (category === "Activation") {
          setSelectedActivationFilter((previous) => Array.from(new Set(previous).add(filter)));
        }
      }  
      if (!checked) {
        if (category === "Platforms") {
          setSelectedPlatformFilter((previous) => {
            const next = new Set(previous);
            next.delete(filter);
            return Array.from(next);
          });
        }
        if (category === "Tasking Types") {
          setSelectedTaskingTypeFilter((previous) => {
            const next = new Set(previous);
            next.delete(filter);
            return Array.from(next);
          });          
        }
        if (category === "Activation") {
          setSelectedActivationFilter((previous) => {
            const next = new Set(previous);
            next.delete(filter);
            return Array.from(next);
          });          
        }         
      }
    }
      
    const handleApplyFilters = () => {
      setPlatformFilter(selectedPlatformFilter);
      setTaskingTypeFilter(selectedTaskingTypeFilter);
      setActivationFilter(selectedActivationFilter);
    }
    
    const handleResetFilters = () => { 
      setSelectedPlatformFilter([]);
      setSelectedTaskingTypeFilter([]);
      setSelectedActivationFilter([]); 
    }
      
    if (loading) {
      return <div className="loader">
        <CircularProgress color="inherit"/>
      </div> 
    }  
    
    return (  
        <> 
        <div className='search-bar-position'> 
          <Box sx={{width: "30rem"}}> 
            <TextField 
              fullWidth 
              size="small"  
              className='search-bar'
              placeholder="Search..."
              autoComplete='off'
              onInput={(e) => { setSearchQuery(e.target.value); }} 
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box> 
          <Box sx={{ display: 'flex', justifyContent:'end'}}> 
            <Button 
              className='form-button' 
              sx={{ marginLeft: '1rem' }} 
              variant="contained" 
              startIcon={<FilterListIcon />} 
              aria-controls={openFilter ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openFilter ? 'true' : undefined}
              onClick={handleOpenFilter}
            >
              <span className='button-text'>
                FILTERS
              </span>  
            </Button> 
            <Menu
              anchorEl={anchorEl}
              open={openFilter}
              onClose={handleCloseFilter} 
              sx={{marginTop: '0.5rem'}}
            >  
              <div className='filter-header'><strong>Platform</strong></div> 
              <Grid container> 
              {platforms.map((platform) => {
                return (
                  <Grid item xs={4}>
                    <span><Checkbox checked={selectedPlatformFilter.includes(platform)} onChange={(e) => updateFilter(e.target.checked, "Platforms", platform)}/>{platform}</span>
                  </Grid>
                )
              })}</Grid>
              <div className='filter-header'><strong>Tasking Type</strong></div> 
              <Grid container> 
              {tasking_types.map((tasking_type) => {
                return (
                  <Grid item xs={4}>
                    <span><Checkbox checked={selectedTaskingTypeFilter.includes(tasking_type)} onChange={(e) => updateFilter(e.target.checked, "Tasking Types", tasking_type)}/>{tasking_type}</span>
                  </Grid>
                )
              })}
              </Grid>
              <div className='filter-header'><strong>Activation</strong></div> 
              <Grid container> 
              {status.map((state) => {
                return (
                  <Grid item xs={4}>
                    <span><Checkbox checked={selectedActivationFilter.includes(state)} onChange={(e) => updateFilter(e.target.checked, "Activation", state)}/>{state ? "Activated" : "Deactivated" }</span>
                  </Grid>
                )
              })}</Grid> 
              <Box sx={{display: "flex", justifyContent: 'center'}}>
                <Button sx={{marginTop: '1rem', marginBottom: '1rem'}} className='form-button' variant="contained" onClick={handleApplyFilters}>
                  <span className='button-text'>
                    APPLY
                  </span>
                </Button> 
                <Button sx={{marginTop: '1rem', marginLeft: '1rem', marginBottom: '1rem'}} className='form-button' variant="contained" onClick={handleResetFilters}>
                  <span className='button-text'>
                    RESET
                  </span>
                </Button> 
              </Box>
            </Menu> 
          </Box> 
        </div> 
        <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>  
          <TableContainer sx={{ maxHeight: 'auto' }}>
            <Table sx={{ tableLayout: 'fixed'}} stickyHeader aria-label="sticky table"> 
              <colgroup>
                  <col width="10%" />
                  <col width="10%" />
                  <col width="10%" />
                  <col width="11%" /> 
                  <col width="35%" />
                  <col width="10%" /> 
                  <col width="10%" />
                  <col width="8%" />
              </colgroup> 
              <EnhancedTableHead 
                order={order}
                orderBy={orderBy} 
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows 
                  .map((row) => {
                    return (
                      <Tooltip 
                        placement="bottom"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -14],
                                },
                              },
                            ],
                          },
                        }}
                        title="Click to view">
                      <TableRow className='table-cell' tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.id === "is_active") {  
                            const pk = row['pk']; 
                            const platform = row['platform'];
                            const tasking_type = row['tasking_type'];
                            return (  
                                <TableCell>
                                  <Switch checked={checked[pk]} onChange={handleActivation(pk, platform, tasking_type)} disabled={isDisabled}/>
                                </TableCell>
                            )        
                          } else { 
                            return (
                              <TableCell className='table-cell' hover component={Link} to={`/social-media-tasking/manage-team/${row.pk}/`} sx={{ fontSize: '14px'}} key={column.id} align={column.align}> 
                                <div className='table-cell'>
                                 {value}
                                </div>
                              </TableCell>
                            );
                          }
                        })} 
                      </TableRow>  
                      </Tooltip>
                    );
                  })}
              </TableBody> 
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />  
          <div className='search-results'>
            Total Activated: {displayedActivated}
          </div> 
        </Paper>  
        {(searchQuery !== "") && 
        <div className='search-results'>
          {rows.length} record(s) found.
        </div>}   
      </>
  );
} 

export default function ManageTaskingTable(props) {
  return (
    <SnackbarProvider maxSnack={5}> 
      <TeamTaskingTable user={props.user} quota={props.quota}/>
    </SnackbarProvider> 
    )
}  