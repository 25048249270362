import { useState, useEffect } from 'react'; 
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText'; 
import Collapse from '@mui/material/Collapse'; 
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore'; 
import PublicIcon from '@mui/icons-material/Public'; 
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';  

    // const dummy = {
    //     Facebook: {
    //       Account: 1,
    //       Hashtag: 2,
    //       Keyword: 3
    //     },
    //     Instagram: {
    //       Account: 4,
    //       Hashtag: 5,
    //       Keyword: 6
    //     },
    //     YouTube: {
    //       Account: 7,
    //       Hashtag: 1,
    //       Keyword: 2
    //     },
    //     Twitter: {
    //       Account: 3,
    //       Hashtag: 4,
    //       Keyword: 5
    //     },      
    //     TikTok: {
    //       Account: 6,
    //       Hashtag: 7,
    //       Keyword: 1
    //     },
    // }
    
export default function QuotaList(props) {      
    const [open, setOpen] = useState({
      Facebook: false, 
      Instagram: false,
      YouTube: false,
      Twitter: false,
      TikTok: false, 
    });
  
    const [quota, setQuota] = useState();
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        if (props.quota !== undefined) {
            let temp = {
                Facebook: {
                  Account: props.quota.facebook_account,
                  Hashtag: props.quota.facebook_hashtag,
                  Keyword: props.quota.facebook_keyword
                },
                Instagram: {
                  Account: props.quota.instagram_account,
                  Hashtag: props.quota.instagram_hashtag,
                  Keyword: props.quota.instagram_keyword
                },
                YouTube: {
                  Account: props.quota.youtube_account,
                  Hashtag: props.quota.youtube_hashtag,
                  Keyword: props.quota.youtube_keyword
                },
                Twitter: {
                  Account: props.quota.twitter_account,
                  Hashtag: props.quota.twitter_hashtag,
                  Keyword: props.quota.twitter_keyword
                },      
                TikTok: {
                  Account: props.quota.tiktok_account,
                  Hashtag: props.quota.tiktok_hashtag,
                  Keyword: props.quota.tiktok_keyword
                },
            }
            setQuota(temp);
            setLoading(false);
        }
    }, [props.quota])  
    
    const handleClick = (platform) => {
        // console.log(props.quota);
        let temp = {...open} 
        temp[platform] = !open[platform]; 
        setOpen(temp); 
    } 

    if (loading) {
      return <></>
    }  
    
    return (
        <>
            <Divider component="li">
                <Typography sx={{color: "black" }} variant="h6" component="div">
                Quota
                </Typography>
            </Divider>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader" 
            >    
                { Object.keys(quota).map((key) => {
                    return (
                        <>
                        <ListItemButton onClick={() => handleClick(key)}>
                            <ListItemIcon>
                                <PublicIcon />
                            </ListItemIcon>
                            <ListItemText primary={key} primaryTypographyProps={{fontSize: '16px', color: 'black'}} />
                            {open[key] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open[key]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                { Object.keys(quota[key]).map((type) => {
                                    return (
                                        <ListItem sx={{ pl: 4 }}>
                                            <ListItemText primary={type + ": " + quota[key][type]} primaryTypographyProps={{fontSize: '14px', color: 'black'}} />
                                        </ListItem>                        
                                    )
                                })}
                            </List>
                        </Collapse>
                        </>
                    )
                })}
            </List> 
        </>
    );
} 