import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';  
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import { get, put } from 'aws-amplify/api';   

const ERROR_MESSAGE = "Category and/or keywords fields are empty!";
const SUCCESS_MESSAGE = "Successfully updated entry.";
const DELETE_MESSAGE = "Successfully deleted entry. Redirecting to previous page..."
const DELETE_FAILED_MESSAGE = "Something went wrong, please try again."

export default function ModifyKeywordForm(props) {  
    const [category, setCategory] = useState(props.data.category);
    const [categories, setCategories] = useState([]);
    const [keywords, setKeywords] = useState(props.data.keywords);  
    const [status, setStatus] = useState(null); 
    const [openDialog, setOpenDialog] = useState(false);  
    const [openAlert, setOpenAlert] = useState(false);   
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false); 
    const [progress, setProgress] = useState(0);
    
    /* Populating existing categories on mount */
    useEffect(() => {
        const getCategories = async () => {
            try {
                const getOperation = get({
                    apiName: 'mgkeywordapi',
                    path: '/mgkeyword'
                });
                const { body } = await getOperation.response; 
                const json = await body.json();
                const temp = new Set();
                json.forEach(x => x.delete ? console.log('') : temp.add(x.category));
                setCategories(Array.from(temp)); 
                // console.log('GET call succeeded: ', json);
            } catch (error) {
                console.log('GET call failed: ', error);
            }
        }   
        getCategories();   
    }, []);  
    
    const tempDeleteData = async () => { 
        try {
            const putOperation = put({
                apiName: 'mgkeywordapi',
                path: '/mgkeyword',
                options: {
                    body: {
                        PK: props.data.PK,
                        category: category,
                        keywords: keywords,
                        creator: props.data.creator,
                        date_created: props.data.date_created,
                        last_modifier: props.user.username,
                        date_modified: new Date().toLocaleString(),
                        hit_type: 'keywords',
                        delete: true,
                        enable: false
                    }
                }
            })
            await putOperation.response;
            // console.log('PUT call succeeded: ', response); 
        } catch (err) { 
            console.log('PUT call failed: ', err);
        }
    }
    
    const handleDelete = () => {
        setOpenDeleteDialog(true);
    }
    
    const handleConfirmDelete = () => {
        tempDeleteData();
        setOpenDeleteDialog(false); 
        setOpenDeleteAlert(true); 
        setTimeout(() => window.location.replace("/keyword-mgt/view"), 1500);
        setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                  return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 100);           
    }
    
    const handleOpen = () => {
        setOpenDialog(true);
    } 
    
    const handleClose = () => {
        setOpenDialog(false);
        setOpenDeleteDialog(false);
    } 
    
    const updateKeywords = async () => {
        try {
            const putOperation = put({
                apiName: 'mgkeywordapi',
                path: '/mgkeyword',
                options: {
                    body: {
                        PK: props.data.PK,
                        category: category,
                        keywords: keywords,
                        creator: props.data.creator,
                        date_created: props.data.date_created,
                        last_modifier: props.user.username,
                        date_modified: Date.parse(new Date()),
                        hit_type: 'keywords',
                        delete: false,
                        enable: true
                    }
                }
            })
            await putOperation.response;
            // console.log('PUT call succeeded: ', response);
            
        } catch (err) {
            setStatus(false); // Different error message needed
            console.log('PUT call failed: ', err);
        }
    } 
    
    const handleConfirm = () => { 
        if (category === null || category.trim() === '' || keywords.length === 0) { 
            setStatus(false); 
            setOpenAlert(true);
        } else {  
            updateKeywords(); 
            setStatus(true);
            setOpenAlert(true);
        } 
        setOpenDialog(false);    
    }   
     
    let alert; 
    if (status) {
        alert = <Alert sx={{fontSize: '16px'}} severity="success" onClose={() => setOpenAlert(false)}>{SUCCESS_MESSAGE}</Alert>; 
    } else if (status === null) {
        alert = <div></div>;
    } else {
        alert = <Alert sx={{ fontSize: '16px' }} severity="error" onClose={() => setOpenAlert(false)}>{ERROR_MESSAGE}</Alert>;
    }
    
    let redirect;
    if (openDeleteAlert) {
        redirect = <Alert sx={{fontSize: '16px'}} severity="success" onClose={() => setOpenDeleteAlert(false)}>{DELETE_MESSAGE}</Alert>; 
    } else if (openDeleteAlert === null) {
        redirect = <div></div>;
    } else {
        redirect = <Alert sx={{ fontSize: '16px' }} severity="error" onClose={() => setOpenDeleteAlert(false)}>{DELETE_FAILED_MESSAGE}</Alert>;
    }    
    
    return (
        <Box className='table-position'> 
            <React.Fragment> 
              <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please ensure that this record <strong>does not</strong> contain any code words.<br/>Are you sure you want to modify entry?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" className='dialog-button' onClick={handleConfirm}>
                        <span className='dialog-button-text'>
                            CONFIRM
                        </span> 
                    </Button>
                    <Button variant="outlined" className='dialog-button' onClick={handleClose} autoFocus>
                        <span className='dialog-button-text'>
                            CANCEL
                        </span> 
                    </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
            <React.Fragment> 
              <Dialog
                open={openDeleteDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete entry?<br/>This action <strong>cannot be undone</strong>.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" className='dialog-button' onClick={handleConfirmDelete}>
                        <span className='dialog-button-text'>
                            DELETE
                        </span> 
                    </Button>
                    <Button variant="outlined" className='dialog-button' onClick={handleClose} autoFocus>
                        <span className='dialog-button-text'>
                            CANCEL
                        </span> 
                    </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
            <Paper elevation={0} className='table'> 
                    <Box>
                        <div className='table-header'>
                            Category
                        </div>
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>    
                            <Autocomplete  
                                value={category} 
                                options={categories}   
                                size="small" 
                                id="free-solo-demo"
                                freeSolo 
                                onChange={(event, value) => setCategory(value)}   
                                renderInput={(params) => 
                                    <TextField size="small" {...params} onChange={(event) => setCategory(event.target.value)} label="Select/add a category"/>}
                            />
                        </Box>
                        <div className='table-header'>
                            Keywords
                        </div>
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>    
                            <Autocomplete
                                value={keywords}
                                multiple 
                                clearIcon={false}
                                size="small" 
                                options={[]} 
                                freeSolo
                                onChange={(event, value) => setKeywords(value)}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                  ))
                                }
                                renderInput={(params) => (
                                    <TextField size="small" {...params} label="Press 'Enter' to add keyword"/>
                                )}
                            />
                            
                        </Box> 
                    </Box>
                    <Box className='button-box-position'>
                        <div className='button-position'>
                            <Button className='form-button' onClick={handleOpen} variant="contained">
                                <span className='button-text'>
                                    UPDATE
                                </span> 
                            </Button>
                            <Button className='form-button' onClick={() => {handleDelete()}} variant="contained">
                                <span className='button-text'>
                                    DELETE
                                </span>  
                            </Button>
                        </div>
                    </Box> 
            </Paper>
            <Box>
                <div className='button-position'>
                    <Button sx={{ marginBottom:'1rem'}} className='form-button' variant="contained" component={Link} to="/keyword-mgt/view" startIcon={<ArrowBackIcon />}>
                        <span className='button-text'>
                            BACK
                        </span> 
                    </Button> 
                </div>
            </Box>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openDeleteAlert}> 
                {redirect} 
            </Snackbar>
            { openDeleteAlert ?  
                <Box sx={{ width: '100%' }}>
                  <LinearProgress variant="determinate" color="success" value={progress} />
                </Box>
                :
                <></>
            }             
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openAlert}> 
                {alert} 
            </Snackbar>  
        </Box>
    );
}
 