import React, { useState, useEffect }from 'react';
import FaceprintDetailCard from '../components/faceprintDetailCard.js';
import ModifyFaceprintForm from '../components/modifyFaceprintForm.js';
import CircularProgress from '@mui/material/CircularProgress';  
import { get } from 'aws-amplify/api'; 

const ModifyFaceprintPage = (props) => { 
    const path = window.location.pathname;
    const pk = path.split("/")[3];   
    const [data, setData] = useState([]); 
    
    useEffect(() => {  
      const getData = async () => {
        try {
            const getOperation = get({
                apiName: 'hcfaceprintapi',
                path: '/hcfaceprint'
            });
            const { body } = await getOperation.response; 
            const json = await body.json();
            const curr = json.filter(function(x) {
                return (!x["delete"] && x["PK"] === pk); 
            }); 
            // console.log(curr);
            setData(curr); 
            } catch (error) {
                console.log('GET call failed: ', error);
            }
        } 
        getData(); 
    }, [pk]);       
      
    if (data.length === 0) {
        return <div className="loader"><CircularProgress sx={{ marginTop: '3rem' }} color="inherit"/></div>
    }  
    
    return (
        <> 
            <div className='detail-card'>
                <FaceprintDetailCard data={data}/>
            </div>
            <div sx={{ marginBottom: "2rem"}}>
              <ModifyFaceprintForm user={props.user} data={data}/>
            </div>
        </>
    );
};

export default ModifyFaceprintPage;


