import React, { useEffect } from 'react'; 
import { Amplify } from 'aws-amplify';  
import configs from './aws-exports.js';
import { get } from 'aws-amplify/api'; 
import { withAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import '@aws-amplify/ui-react/styles.css';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import HomePage from './homePage.js';
import AddKeywordPage from './keyword-mgt/pages/addKeywordPage.js';
import ViewAllKeywordPage from './keyword-mgt/pages/viewAllKeywordPage.js';
import ModifyKeywordPage from './keyword-mgt/pages/modifyKeywordPage.js';
import AddGroupTagPage from './group-tagging/pages/addGroupTagPage.js';
import ViewAllGroupTagPage from './group-tagging/pages/viewAllGroupTagPage.js';
import ModifyGroupTagPage from './group-tagging/pages/modifyGroupTagPage.js';
import AddTaskingPage from './social-media-tasking/pages/addTaskingPage.js';
import ManageTaskingPage from './social-media-tasking/pages/manageTaskingPage.js';
import ModifyTaskingPage from './social-media-tasking/pages/modifyTaskingPage.js';
import ViewAllTaskingPage from './social-media-tasking/pages/viewAllTaskingPage.js';
import ViewTaskingPage from './social-media-tasking/pages/viewTaskingPage.js';
import NavigationBar from './shared/navigationBar.js'; 
import DownloadPage from './file-transfer-host/pages/downloadPage.js'; 
import AddFaceprintPage from './faceprint-mgt/pages/addFaceprintPage.js';
import ViewAllFaceprintPage from './faceprint-mgt/pages/viewAllFaceprintPage.js';
import ModifyFaceprintPage from './faceprint-mgt/pages/modifyFaceprintPage.js';
import './App.css';

Amplify.configure(configs);
let theme = responsiveFontSizes(createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Inter Tight',
      textTransform: 'none', 
    },
  }, 
}));
theme = responsiveFontSizes(theme);

function App({user, signOut}) {  
    useEffect(() => {  
      const getTeam = async () => {
        try {
          const getOperation = get({ 
          apiName: 'hcusermapapi',
          path: '/hcusermap' 
          });
          const { body } = await getOperation.response;
          const json = await body.json();
          const username = user.username; 
          let team = ""; // Only to check if user-to-team mapping exists for current user
          json.forEach((obj) => { 
            if (obj.username === username) { 
              team = obj.team;  
              user.team = obj.team; 
              return;
            }});
          if (team.length === 0) { 
            console.log("User-to-team mapping for this user does not exist.");
          } 
          // console.log('GET call succeeded: ', json); 
        } catch (error) {
          // console.log('GET call failed: ', error);
        }
      } 
      getTeam();   
    }, [user]);
    
  return (
      <ThemeProvider theme={theme}>
        <div className="App">  
          <BrowserRouter>  
              <NavigationBar signOut={signOut}/>
              <Routes>
                <Route path="/" element={<Navigate replace to="/home" />} />
                <Route index path="/home" element={<HomePage user={user}/>} />   
                <Route exact path="/keyword-mgt/add" element={<AddKeywordPage user={user}/>} /> 
                <Route exact path="/keyword-mgt/view" element={<ViewAllKeywordPage user={user}/>} /> 
                <Route exact path="/keyword-mgt/view/:pk" element={<ModifyKeywordPage user={user}/>} /> 
                <Route exact path="/id-tagging/add" element={<AddGroupTagPage user={user}/>} />
                <Route exact path="/id-tagging/view" element={<ViewAllGroupTagPage user={user}/>} /> 
                <Route exact path="/id-tagging/view/:pk" element={<ModifyGroupTagPage user={user}/>} />
                <Route exact path="/social-media-tasking/add" element={<AddTaskingPage user={user}/>} /> 
                <Route exact path="/social-media-tasking/manage-team" element={<ManageTaskingPage user={user}/>} />
                <Route exact path="/social-media-tasking/manage-team/:pk" element={<ModifyTaskingPage user={user}/>} />
                <Route exact path="/social-media-tasking/view" element={<ViewAllTaskingPage user={user}/>} /> 
                <Route exact path="/social-media-tasking/view/:pk" element={<ViewTaskingPage user={user}/>} />
                <Route exact path="/faceprint-mgt/add" element={<AddFaceprintPage user={user}/>} /> 
                <Route exact path="/faceprint-mgt/view" element={<ViewAllFaceprintPage user={user}/>} />
                <Route exact path="/faceprint-mgt/view/:pk" element={<ModifyFaceprintPage user={user}/>} />
                <Route path="/media-download" element={<DownloadPage/>} /> 
              </Routes> 
          </BrowserRouter>
        </div>
      </ThemeProvider>
  );
}

export default withAuthenticator(App, { hideSignUp: true }); 

