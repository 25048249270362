import React from 'react';  
import Box from '@mui/material/Box';
import Card from '@mui/material/Card'; 
import CardContent from '@mui/material/CardContent';  

export default function TaskingDetailCard(props) {    
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <Box className='card-content'> 
          <CardContent>
            <div className='card-header'>
              Entry Details
            </div>
            <div className='card-subtext'>
              Creator: {props.data.creator}
            </div> 
            <div className='card-subtext'>
              Creator's Team: {props.data.team}
            </div> 
            <div className='card-subtext'>
              Date of Creation: {props.data.date_created}
            </div> 
            <div className='card-subtext'>
              Last Modified By: {props.data.last_modifier}
            </div> 
            <div className='card-subtext'>
              Date of Modification: {props.data.date_modified}
            </div> 
            <div className='card-subtext'>
              Latest Processing Status: {props.data.latest_status} 
            </div>   
          </CardContent> 
        </Box>
      </Card>
    </Box>
  );
}