import React from 'react';
import FaceprintList from '../components/faceprintList.js'; 

const ViewAllFaceprintPage = (props) => {
    return (
        <>
            <h1 className='header' >
                View All Faceprint
            </h1> 
            <div className='keyword-table'>
                <FaceprintList user={props.user}/>
            </div>
        </>
    );
};

export default ViewAllFaceprintPage;