import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'; 
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WorkIcon from '@mui/icons-material/Work';
import { Link } from 'react-router-dom';
import './homePage.css';  
// import pdf from './shared/user-guide.pdf';
import { getUrl } from 'aws-amplify/storage';

const KIBANA_DASHBOARD = "https://treno-elasticsearch.auth.ap-southeast-1.amazoncognito.com/login?response_type=code&client_id=5hek69svmipf0c0a7tsk3i3gof&redirect_uri=https://search-treno-elasticsearch-jrp7ehnnmoaq5kiznodd2ckn34.ap-southeast-1.es.amazonaws.com/_plugin/kibana/app/kibana&state=aa283534-e30c-4620-82e2-66f1113562f4#/"

const HomePage = (props) => {  
  const [userGuideUrl, setUserGuideUrl] = useState("");
  
  useEffect(() => { 
    const getUrlResult = async () => {
      try { 
        const url = await getUrl({
          key: 'userguide.pdf',
          options: {
            accessLevel: 'public',  
            validateObjectExistence: false,  
          },
        })  
        return url.url.toString();
      } catch (err) {
        console.log(err);
      }
    } 
    getUrlResult().then((value) => setUserGuideUrl(value)); 
  }, []);
   
  return (
    <>
      <Box className="home-content"> 
        <div className="content-text">
          <div >
            <div className="title">
              Welcome back, <strong>{props.user.username}</strong>!
            </div> 
            <div className="buttons-div"> 
              <Button 
                variant="outlined" 
                className="home-buttons" 
                component={Link} 
                to="/keyword-mgt/view" 
                endIcon={<WorkIcon />}
              >
                View All Keywords
              </Button>
              <Button variant="outlined" className="home-buttons" component={Link} to="/id-tagging/view" endIcon={<WorkIcon />}>
                View All ID Tags
              </Button>
              <Button variant="outlined" className="home-buttons" component={Link} to={KIBANA_DASHBOARD} endIcon={<OpenInNewIcon />}>
                View Dashboard
              </Button>
            </div>
          </div>
          <div>
            <h2>
            Our Purpose 
            </h2>
            <p> We provide a comprehensive self-help platform tailored to optimize users' workflow on cloud. By offering intuitive tools, users can navigate quickly through the intricate areas ranging from keyword management, ID-tagging, social media tasking management, 
            to managing facial recognition data securely. We aim to simplify the workflow while building towards an all-in-one platform to complement today's workflow. Refer to our user guide <a href={userGuideUrl} download="userguide.pdf" target="_blank" rel="noreferrer">here</a>. </p> 
            <h2>
              Our Features 
            </h2>
            <ul className='ul-features'>
              <li>
                <strong> Keyword Management </strong> 
                aims to help users group keywords that fall under the same category. Users can also modify or delete irrelevant entries. 
                <br/>This serves as a preliminary stage for users to configure the keywords that will be used for keyword hitting at a later stage of their workflow.
              </li>
              <li>
                <strong> ID Tagging </strong> 
                aims to help users group IDs of accounts, social media groups, chat groups and/or pages that fall under the same category. Users can modify or delete irrelevant entries. 
                <br/>This serves as a preliminary stage for users to configure the IDs that will be used at a later stage of their workflow to sieve out similarities between different groups.
              </li> 
              <li>
                <strong> Social Media Tasking </strong> 
                aims to help users configure taskings for social media platforms such as Facebook, Twitter, TikTok etcetra. Users can modify or delete irrelevant entries created by their own teams, and view entries created by other teams.
                <br/>This serves as a platform where users can activate/deactivate entries (subjected to quota availability) created by their own teams, which will be used for hitting at a later stage of their workflow.
              </li> 
              <li>
                <strong> Faceprint Management </strong> 
                aims to help users configure facial images of specific targets and tag them to multiple categories. Users can modify or delete irrelevant entries.
                <br/>This serves as a platform where users can activate/deactivate entries temporarily when not in use. Activated entries will be used for hitting at a later stage of their workflow, to sieve out specific targets from various multimedia files.
              </li> 
            </ul> 
            <h2>
              Latest Update Notes
            </h2>
            <ul className='ul-features'>
              <li>
                  <strong> Faceprint Management </strong>  
                  <br/>Users can now manage restrictions for entries created by them/their team. They can choose to set their entries to public, or restrict view and edit access to their team.  
              </li> 
            </ul>
            <h2>
              What's Next
            </h2>
            <ul className='ul-features'> 
              <li>
                <strong> Content Viewer </strong>  
              </li>
              <li>
                <strong> Case Management </strong>  
              </li>
              <li>
                <strong> Network Visualization </strong>  
              </li>
              <li>
                <strong> Chat Assistant </strong>  
              </li>
              <li>
                <strong> Search-engine Integration </strong>  
              </li>
            </ul>
          </div>
        </div>  
      </Box> 
    </>
  );
};

export default HomePage; 