import React, { useState, useEffect }from 'react';
import GroupTagDetailCard from '../components/groupTagDetailCard.js';
import ModifyGroupTagForm from '../components/modifyGroupTagForm.js';
import CircularProgress from '@mui/material/CircularProgress'; 
import { get } from 'aws-amplify/api'; 
import './modifyGroupTagPage.css';

const ModifyGroupTagPage = (props) => { 
    const path = window.location.pathname;
    const pk = path.split("/")[3];   
    const [data, setData] = useState([]);
    
    useEffect(() => {
      const getGroupTag = async () => {
        try {
          const getOperation = get({ 
          apiName: 'mggroupapi',
          path: '/mggroup' 
        });
          const { body } = await getOperation.response;
          const json = await body.json(); 
          const temp = json.filter(function(x) {
            return (!x["delete"] && x["PK"] === pk); 
          }); 
          setData(temp[0]);  
          // console.log('GET call succeeded: ', json); 
        } catch (error) {
          console.log('GET call failed: ', error);
        }
      }
      getGroupTag(); 
    }, [pk]) 
     
    if (data.length === 0) {
        return <div className="loader"><CircularProgress sx={{ marginTop: '3rem' }} color="inherit"/></div>
    } 
    
    return (
        <div sx={{ overflow: 'auto'}}> 
            <div className='detail-card'>
                <GroupTagDetailCard data={data}/>
            </div>                    
            <ModifyGroupTagForm user={props.user} data={data}/> 
        </div>
    );
};

export default ModifyGroupTagPage;