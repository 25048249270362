import React from 'react'; 
import AddGroupTagForm from "../components/addGroupTagForm.js";

const AddGroupTagPage = (props) => {
    return <>
        <h1 className='header'>
            Add ID Tag 
        </h1>
        <AddGroupTagForm user={props.user}/>
    </>
};

export default AddGroupTagPage;