import { useState, useEffect } from 'react';
import ManageTaskingTable from '../components/manageTaskingTable.js'; 
import Box from '@mui/material/Box'; 
import QuotaList from '../components/quotaList.js';
import { get } from 'aws-amplify/api'; 
import './manageTaskingPage.css';

const ManageTaskingPage = (props) => {   
  const [quota, setQuota] = useState();
  const [current, setCurrent] = useState();
  
  useEffect(() => {    
    const getQuota = async () => {
      try {
        const getOperation = get({ 
        apiName: 'hcquotamapapi',
        path: '/hcquotamap' 
        });
        const { body } = await getOperation.response;
        const json = await body.json();
        let selectedQuotaList = json.find(list => list.team === (props.user.team + "-quota"));
        let selectedCurrentList = json.find(list => list.team === (props.user.team + "-current"));
        setQuota(selectedQuotaList);
        setCurrent(selectedCurrentList); 
        // console.log('GET call succeeded: ', json);   
      } catch (error) {
        console.log('GET call failed: ', error);
      }
    }
    getQuota();
  }, [props.user.team])
    
  return (
    <>
        <h1 className='header'>
            Manage Team's Tasking
        </h1> 
        <Box sx={{ display:'flex', flexDirection: 'row'}}>
          <div className='quota-list'>
            <QuotaList user={props.user} quota={quota}/>
          </div>
          <div className='manage-tasking-table'>
            <ManageTaskingTable user={props.user} quota={quota} current={current}/>
          </div> 
        </Box>
    </>
    );
};

export default ManageTaskingPage; 