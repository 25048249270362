import React from 'react';  
import Box from '@mui/material/Box';
import Card from '@mui/material/Card'; 
import CardContent from '@mui/material/CardContent'; 
// import './groupTagDetailCard.css'; 

export default function FaceprintDetailCard(props) {    
  // console.log("props.data: ", props.data[0])
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <Box className='card-content'> 
          <CardContent>
            <div className='card-header'>
              Entry Details
            </div>
            <div className='card-subtext'>
              Creator: {props.data[0].creator}
            </div> 
            <div className='card-subtext'>
              Creator's Team: {props.data[0].creator_team}
            </div> 
            <div className='card-subtext'>
              Date of Creation: {props.data[0].date_created}
            </div> 
            <div className='card-subtext'>
              Last Modified By: {props.data[0].last_modifier === undefined ? '-' : props.data[0].last_modifier}
            </div> 
            <div className='card-subtext'>
              Date of Modification: {props.data[0].date_modified === undefined ? '-' : props.data[0].date_modified}
            </div> 
          </CardContent> 
        </Box>
      </Card>
    </Box>
  );
}