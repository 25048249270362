/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "hcfaceprintapi",
            "endpoint": "https://v4g6lq2uga.execute-api.ap-southeast-1.amazonaws.com/master",
            "region": "ap-southeast-1"
        },
        {
            "name": "hcquotamapapi",
            "endpoint": "https://roh7gmmug9.execute-api.ap-southeast-1.amazonaws.com/master",
            "region": "ap-southeast-1"
        },
        {
            "name": "hctaskingapi",
            "endpoint": "https://ri0wqxuco5.execute-api.ap-southeast-1.amazonaws.com/master",
            "region": "ap-southeast-1"
        },
        {
            "name": "hcusermapapi",
            "endpoint": "https://ycta9g5sk5.execute-api.ap-southeast-1.amazonaws.com/master",
            "region": "ap-southeast-1"
        },
        {
            "name": "mggroupapi",
            "endpoint": "https://gltrjtf0te.execute-api.ap-southeast-1.amazonaws.com/master",
            "region": "ap-southeast-1"
        },
        {
            "name": "mgkeywordapi",
            "endpoint": "https://51ckbr7ewe.execute-api.ap-southeast-1.amazonaws.com/master",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-1:573d1ff1-b3a2-4bcf-8d24-01226c094f77",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_phw8HCvz1",
    "aws_user_pools_web_client_id": "1uktt222e57nofaiug1nop2sdi",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-southeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "hcfaceprint-master",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "hcquotamap-master",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "hctasking-master",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "hcusermap-master",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "mggroup-master",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "mgkeyword-master",
            "region": "ap-southeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "hcs3bucket73259-master",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
