import * as React from 'react'; 
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import './navigationBar.css';

export default function TaskingMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <span className='menu-subheader' onClick={handleClick}>
        Social Media Tasking
      </span>
      <Menu 
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem sx={{fontSize: "15px"}} component={Link} to="/social-media-tasking/add" onClick={handleClose}>Add Tasking</MenuItem>
        <MenuItem sx={{fontSize: "15px"}} component={Link} to="/social-media-tasking/manage-team" onClick={handleClose}>Manage Team's Tasking</MenuItem> 
        <MenuItem sx={{fontSize: "15px"}} component={Link} to="/social-media-tasking/view" onClick={handleClose}>View All Tasking</MenuItem> 
      </Menu>
    </div>
  );
}