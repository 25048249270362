import React from 'react';  
import Box from '@mui/material/Box';
import Card from '@mui/material/Card'; 
import CardContent from '@mui/material/CardContent'; 
import './keywordDetailCard.css'; 
import {format} from 'date-fns'

function renderDate(date) {
  if (!date) {
      return '';
  }
  return format(new Date(date), 'Pp');
}
 

export default function KeywordDetailCard(props) {    
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <Box className='card-content'> 
          <CardContent>
            <div className='card-header'>
              Entry Details
            </div>
            <div className='card-subtext'>
              Creator: {props.data.creator}
            </div> 
            <div className='card-subtext'>
              Date of Creation: {renderDate(props.data.date_created)}
            </div> 
            <div className='card-subtext'>
              Last Modified By: {props.data.last_modifier === undefined ? '-' : props.data.last_modifier}
            </div> 
            <div className='card-subtext'>
              Date of Modification: {props.data.date_modified === undefined ? '-' : renderDate(props.data.date_modified)}
            </div> 
          </CardContent> 
        </Box>
      </Card>
    </Box>
  );
}