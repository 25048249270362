import React from 'react';  
import ViewAllTaskingTable from '../components/viewAllTaskingTable.js';
import './viewAllTaskingPage.css';

const ViewAllTaskingPage = (props) => {
  return (
    <>
        <h1 className='header'>
            View All Tasking
        </h1>
        <div className='view-tasking-table'>
          <ViewAllTaskingTable user={props.user}/>
        </div>
    </>
    );
};

export default ViewAllTaskingPage; 