import * as React from 'react'; 
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'; 
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu'; 

export default function SettingMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
   
  return (
    <div>  
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu" 
            onClick={handleClick}
        >
            <MenuIcon />
        </IconButton>    
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem sx={{fontSize: "15px"}} onClick={handleClose}>Account Settings</MenuItem> 
            <MenuItem sx={{fontSize: "15px"}} onClick={props.signOut}>Log Out</MenuItem> 
        </Menu>
    </div>
  );
}

 