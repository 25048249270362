import React from 'react';
import AddTaskingForm from '../components/addTaskingForm.js'; 

const AddTaskingPage = (props) => {
  return (
    <>
        <h1 className='header'>
            Add Tasking
        </h1>
        <AddTaskingForm user={props.user}/>
    </>
    );
};

export default AddTaskingPage;