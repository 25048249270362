import React from 'react';
import KeywordTable from '../components/keywordTable.js';
import './viewAllKeywordPage.css';

const ViewAllKeywordPage = () => {
    return (
        <>
            <h1 className='header' >
                Keyword Management
            </h1> 
            <div className='keyword-table'>
                <KeywordTable/>
            </div>
        </>
    );
};

export default ViewAllKeywordPage;