import React, { useState, useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close'; 
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';  
import FormControl from '@mui/material/FormControl'; 
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar'; 
import LinearProgress from '@mui/material/LinearProgress'; 
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper'; 
import Select from '@mui/material/Select'; 
import Snackbar from '@mui/material/Snackbar';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import FileUpload from "react-mui-fileuploader"
import { put } from 'aws-amplify/api';  
import { uploadData } from 'aws-amplify/storage'; 
import { v4 as uuid } from "uuid";
import { createTheme, ThemeProvider } from '@mui/material/styles'; 
import { getUrl, remove } from 'aws-amplify/storage';  
import '@aws-amplify/ui-react/styles.css';  
import fetch from "node-fetch";
import { get } from 'aws-amplify/api';  

const ACCESS_LEVEL = "public";
const PATH = "faceprint/";
const ERROR_MESSAGE = "Name, categories and/or faceprint fields are empty!";
const SUCCESS_MESSAGE = "Successfully updated entry."; 
const DELETE_MESSAGE = "Successfully deleted entry. Redirecting to previous page..."
const DELETE_FAILED_MESSAGE = "Something went wrong, please try again." 

export default function ModifyFaceprintForm(props) {  
    let entry_pk = props.data[0].PK;
    const [name, setName] = useState(props.data[0].name);
    const [categories, setCategories] = useState(props.data[0].categories); 
    const [enable, setEnable] = useState(props.data[0].enable); 
    const [openDialog, setOpenDialog] = useState(false);  
    const [openAlert, setOpenAlert] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [status, setStatus] = useState(null); 
    const [currentHashMap, setCurrentHashMap] = useState(props.data[0].s3_hash_map); 
    const [currentStatusMap, setCurrentStatusMap] = useState(props.data[0].s3_status_map); 
    const [currentStatuses, setCurrentStatuses] = useState(Object.values(props.data[0].s3_status_map)); 
    const [currentUrls, setCurrentUrls] = useState([]);
    const [deletedPaths, setDeletedPaths] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]); 
    const [progress, setProgress] = useState(0);
    const [existingCategories, setExistingCategories] = useState([]);
    const [restricted, setRestricted] = useState(props.data[0].restricted);
    const [manageRestriction, setManageRestriction] = useState(props.data[0].creator_team === props.user.team);
    // const manageRestriction = props.data[0].creator_team === props.user.team;
    // const [isDisabled, setIsDisabled] = useState(false);
    
    useEffect(() => {
        const getExistingCategories = async () => {
            try {
                const getOperation = get({
                    apiName: 'hcfaceprintapi',
                    path: '/hcfaceprint'
                });
                const { body } = await getOperation.response; 
                const json = await body.json();
                // const temp = new Set();
                let temp = [];
                json.forEach(x => x.delete ? console.log('') : (x.access !== 'everyone' && x.creator_team !== props.user.team) ? console.log('') : temp = [...temp, ...x.categories]); 
                let existing = [...new Set(temp)]
                // console.log(existing);
                setExistingCategories(existing); 
                // console.log('GET call succeeded: ', json);
            } catch (error) {
                console.log('GET call failed: ', error);
            }    
        }    
        getExistingCategories();
    }, [props.user.team])     
    
    useEffect(() => {
        const paths = Object.keys(currentHashMap);
        const getUrlResult = async (path) => {
            try { 
              const url = await getUrl({
                key: path,
                options: {
                  accessLevel: 'public' ,  
                  validateObjectExistence: false,  
                },
              })  
              return url.url.toString();
            } catch (err) {
              console.log(err);
            }
        }     
        let temp = []; 
        paths.forEach(path => {
            // const status = currentStatusMap[path]; 
            temp.push(getUrlResult(path));
            // map[getUrlResult(path)] = status;
        })
        Promise.all(temp).then((values) => setCurrentUrls(values)); 
    }, [currentHashMap])
     
    const handleOpen = () => {
        setOpenDialog(true);
    } 
    
    const handleClose = () => {
        setOpenDialog(false);
        setOpenDeleteDialog(false);
    }
        
    const handleFilesChange = (files) => { 
      setFilesToUpload([ ...files ])  
    }; 
    
    const uploadToS3 = async (file, path) => {  
        try {
            const result = await uploadData({
                key: path,
                data: file,
                options: {
                    accessLevel: ACCESS_LEVEL
                }
            }).result; 
            console.log('Succeeded: ', result);
        } catch (error) {
            console.log('Error : ', error); 
        } 
    }   
    
    const handleConfirm = () => { 
        if (name.trim() === '' || categories.length === 0 || (filesToUpload.length === 0 && currentUrls.length === 0)) { 
            setStatus(false); 
            setOpenAlert(true);
        } else {     
            let updatedHashMap = currentHashMap; 
            let updatedStatusMap = currentStatusMap;
            if (deletedPaths.length > 0) {
                deletedPaths.forEach((key) => deleteFromS3(key));
            }  
            if (filesToUpload.length > 0) {
                let pathsToUpload = []; 
                filesToUpload.forEach((file) => { 
                    const file_pk = uuid().slice(0, 10); 
                    const path = PATH + file_pk + '_' + file.name;
                    pathsToUpload.push(path);  
                    updatedHashMap[path] = ""; 
                    updatedStatusMap[path] = "Pending";
                }); 
                for (let i = 0; i < filesToUpload.length; i++) {
                    const file = filesToUpload[i];
                    const path = pathsToUpload[i]; 
                    uploadToS3(file, path);
                }
            }  
            updateDynamoDB(updatedHashMap, updatedStatusMap); 
            setStatus(true);
            setOpenAlert(true);
        } 
        setOpenDialog(false);    
    }
    
    const updateDynamoDB = async (updated_hash_map, updated_status_map) => { 
        await fetch('https://kv2fa2wh23.execute-api.ap-southeast-1.amazonaws.com/Dev/faceprint', { 
            headers: {
                authorizationToken: "hunny-allow-1713316693000"
            },
            method: 'POST',
            body: JSON.stringify({
               record_id: entry_pk 
            })}) 
            .catch((err) => {
               console.log('POST call failed: ', err.message);
        });
        try {
            const putOperation = put({
                apiName: 'hcfaceprintapi',
                path: '/hcfaceprint',
                options: {
                    body: {
                        PK: entry_pk,
                        name: name,
                        categories: categories, 
                        s3_hash_map: updated_hash_map,   
                        s3_status_map: updated_status_map,
                        creator: props.data[0].creator,
                        date_created: props.data[0].date_created,
                        date_modified: new Date().toLocaleString(),
                        last_modifier: props.user.username,
                        hit_type: 'faceprint',
                        delete: false,
                        enable: enable, 
                        status: 'pending', 
                        creator_team: props.data[0].creator_team,
                        restricted: restricted
                        
                    }
                }
            })
            await putOperation.response; 
        } catch (err) {
            setStatus(false); 
        }
    }
        
    // Update states currentUrls and currentHashMap 
    const deleteUrl = (url, index) => {
        const file_pk = url.split("_")[0].split("faceprint/")[1];
        const keys = Array.from(Object.keys(currentHashMap));
        let deleted_path; 
        keys.forEach((key) => {
            if (key.includes(file_pk)) {
                deleted_path = key;   
                setDeletedPaths([...deletedPaths, deleted_path]);
            } 
        });  
        delete currentHashMap[deleted_path];  
        delete currentStatusMap[deleted_path];
        const updatedUrls = currentUrls.filter(x => x !== url);
        // console.log("Current statuses", currentStatuses);
        // console.log("Index", index);
        currentStatuses.splice(index, 1); 
        // console.log("Updated statuses", currentStatuses); 
        // console.log("Updated statuses map", currentStatusMap);
        setCurrentUrls(updatedUrls);   
        setCurrentStatuses(currentStatuses);
        setCurrentHashMap(currentHashMap);  
        setCurrentStatusMap(currentStatusMap);
    } 
    
    const deleteFromS3 = async (path) => {
        try {
            const result = await remove({ 
                key: path, 
                options: { 
                  accessLevel: 'guest'
                } 
            });
            console.log('Succeeded: ', result);
        } catch (error) {
            console.log('Error ', error);
        }
    }
    
    const deleteData = async () => { 
        let paths = Object.keys(props.data[0].s3_hash_map);
        paths.forEach((path) => deleteFromS3(path)); 
        try {
            const putOperation = put({
                apiName: 'hcfaceprintapi',
                path: '/hcfaceprint',
                options: {
                    body: {
                        PK: entry_pk,
                        name: name,
                        categories: categories, 
                        s3_hash_map: props.data[0].s3_hash_map, 
                        s3_status_map: props.data[0].s3_status_map,
                        creator: props.data[0].creator,
                        date_created: props.data[0].date_created,
                        date_modified: props.data[0].date_modified,
                        last_modifier: props.data[0].last_modifier,
                        hit_type: 'faceprint',
                        delete: true,
                        enable: false, 
                        status: props.data[0].status,
                        creator_team: props.data[0].creator_team,
                        restricted: props.data[0].restricted
                    }
                }
            })
            await putOperation.response; 
        } catch (err) { 
            console.log('Error ', err);
        }
    } 
    
    const handleDelete = () => {
        setOpenDeleteDialog(true);
    }
    
    const handleConfirmDelete = () => {
        deleteData();
        setOpenDeleteDialog(false); 
        setOpenDeleteAlert(true); 
        setTimeout(() => window.location.replace("/faceprint-mgt/view"), 1500);
        setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                  return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 100);
    }
    
    const theme = createTheme({
        typography: {
            allVariants: {
              fontFamily: 'Inter Tight',
              textTransform: 'none', 
              fontSize: '18px'
            },
          },
      components: { 
        MuiButton: {
          styleOverrides: { 
            root: { 
              backgroundColor: '#605958', 
              color: 'white',
              fontSize: '14px',
              fontWeight: '600',
              padding: '6px 16px',  
              "&:hover": {
                  backgroundColor: 'rgb(96,89,88,0.8)', 
              }
            },            
          },
        },
      },
    });
 
    let alert; 
    if (status) {
        alert = <Alert sx={{fontSize: '16px'}} severity="success" onClose={() => setOpenAlert(false)}>{SUCCESS_MESSAGE}</Alert>; 
    } else if (status === null) {
        alert = <div></div>;
    } else {
        alert = <Alert sx={{ fontSize: '16px' }} severity="error" onClose={() => setOpenAlert(false)}>{ERROR_MESSAGE}</Alert>;
    }
    
    let redirect;
    if (openDeleteAlert) {
        redirect = <Alert sx={{fontSize: '16px'}} severity="success" onClose={() => setOpenDeleteAlert(false)}>{DELETE_MESSAGE}</Alert>; 
    } else if (openDeleteAlert === null) {
        redirect = <div></div>;
    } else {
        redirect = <Alert sx={{ fontSize: '16px' }} severity="error" onClose={() => setOpenDeleteAlert(false)}>{DELETE_FAILED_MESSAGE}</Alert>;
    }
    
    return (
        <Box className='table-position'> 
            <React.Fragment> 
              <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please ensure that this record <strong>does not</strong> contain any code words.<br/>Are you sure you want to modify entry?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" className='dialog-button' onClick={handleConfirm}>
                        <span className='dialog-button-text'>
                            CONFIRM
                        </span> 
                    </Button>
                    <Button variant="outlined" className='dialog-button' onClick={handleClose} autoFocus>
                        <span className='dialog-button-text'>
                            CANCEL
                        </span> 
                    </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
            <React.Fragment> 
              <Dialog
                open={openDeleteDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete entry?<br/>This action <strong>cannot be undone</strong>.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" className='dialog-button' onClick={handleConfirmDelete}>
                        <span className='dialog-button-text'>
                            DELETE
                        </span> 
                    </Button>
                    <Button variant="outlined" className='dialog-button' onClick={handleClose} autoFocus>
                        <span className='dialog-button-text'>
                            CANCEL
                        </span> 
                    </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
            <Paper elevation={0} sx={{minHeight: "10vh"}} className='table'>  
                <div className='table-header'>
                    Name
                </div> 
                <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                    <FormControl fullWidth> 
                        <TextField 
                            value={name}
                            id="outlined-basic" 
                            size="small" 
                            variant="outlined"  
                            onChange={(event) => setName(event.target.value)}
                        />     
                    </FormControl>
                </Box>  
                <div className='table-header'>
                    Categories
                </div> 
                <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>    
                    <Autocomplete
                        value={categories}
                        multiple
                        clearIcon={false}
                        size="small" 
                        options={existingCategories} 
                        freeSolo
                        onChange={(event, value) => setCategories(value)}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                            <TextField size="small" {...params} sx={{ fontSize: '8px'}} label="Press 'Enter' to add category"/>
                        )}
                    /> 
                </Box>  
                <div className='table-header'>
                    Restrict Access 
                    <Tooltip title="Restrict access to view/edit this entry. Only the creator's team can manage this.">
                        <HelpOutlineIcon sx={{fontSize: 'small'}}/> 
                    </Tooltip> 
                </div>  
                <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}> 
                    <FormControl sx={{ minWidth: 120 }}> 
                        <Select  
                            value={restricted} 
                            onChange={(event) => setRestricted(event.target.value)}
                            size="small"
                            displayEmpty 
                            disabled={!manageRestriction}
                        >
                            <MenuItem value={"everyone"}>Everyone can view and edit</MenuItem>
                            <MenuItem value={"group"}>Only my team can view and edit</MenuItem> 
                        </Select>
                    </FormControl>
                </Box>
                <div className='table-header'>
                    Activated 
                    <Tooltip title="Activate entry for faceprints to be used in hitting.">
                        <HelpOutlineIcon sx={{fontSize: 'small'}}/> 
                    </Tooltip> 
                </div> 
                <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>  
                    <Switch
                      checked={enable}
                      onChange={() => setEnable(!enable)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Box>
                <div className='table-header'>
                        Faceprint
                </div>
                <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>
                    <ImageList cols={4}>
                        {currentUrls.map((url, index) => (
                            <ImageListItem 
                                key={url} 
                                sx={{ 
                                        '&:hover': {
                                        cursor: 'default', 
                                    }, 
                                }}
                            >  
                                <Tooltip 
                                    title={ currentStatuses[index].includes("error") ? currentStatuses[index].split(":")[1] : ""}
                                    slotProps={{
                                        popper: {
                                            modifiers: [{
                                                name: 'offset',
                                                options: { offset: [0, -14] },
                                            }]
                                        },
                                    }}
                                >
                                    <Chip 
                                        sx={{ 
                                            marginRight: "5px", 
                                            marginBottom: "5px", 
                                            maxWidth: "5rem", 
                                            position: "absolute", 
                                            bottom:"0", 
                                            right:"0",  
                                            backgroundColor: currentStatuses[index] === 'Completed' ? "#41a46e" : currentStatuses[index] === 'Pending' ? "#f1ce63" : "#eb5858", 
                                            color: currentStatuses[index] === 'Completed' ? "#d1e3e5" : currentStatuses[index] === 'Pending' ? "#f1eae4" : "#efd9e6"
                                        }}  
                                        size="small"  
                                        label={ currentStatuses[index].split(":")[0] } 
                                    />    
                                </Tooltip>
                                <img
                                    srcSet={`${url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    src={url}
                                    style={{ width: "100%", height: "250px", objectFit: "cover"}}
                                    alt={""}
                                    loading="lazy"  
                                /> 
                                <ImageListItemBar
                                    actionIcon={
                                        <IconButton sx={{ color: 'white' }} aria-label='Delete' onClick={() => deleteUrl(url, index)} >
                                            <CloseIcon />
                                        </IconButton>
                                    }
                                    actionPosition="left"
                                    position="top"
                                    sx={{
                                        background:
                                          'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                          'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                      }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList> 
                </Box>                
                <Box sx={{padding: '0% 1% 3% 1%'}}> 
                    <ThemeProvider theme={theme}>
                        <FileUpload
                          getBase64={false}
                          multiFile={true}
                          disabled={false}
                          title=""
                          header="Drag to drop here or"
                          leftLabel=""
                          rightLabel=""
                          buttonLabel="Browse Files"
                          buttonRemoveLabel="Clear All"  
                          maxFileSize={10}
                          maxUploadFiles={0}
                          maxFilesContainerHeight={357}
                          acceptedType={'image/*'}
                          errorSizeMessage={'fill it or remove it to use the default error message'}
                          allowedExtensions={['jpg', 'jpeg', 'png', 'gif']}
                          onFilesChange={handleFilesChange}  
                          BannerProps={{ elevation: 0,   sx: { backgroundColor: "rgb(0,0,0,0)", padding: '3%', border: '1px dashed', borderColor: "rgb(0,0,0,0.3)", }}}
                          ContainerProps={{ elevation: 0, sx: { backgroundColor: "rgb(255,255,255,0)", border: 'none'}}}
                          showPlaceholderImage={false}
                          PlaceholderImageDimension={{
                            xs: { width: 128, height: 128 },
                            sm: { width: 128, height: 128 },
                            md: { width: 164, height: 164 },
                            lg: { width: 256, height: 256 }
                          }}
                          PlaceholderGridProps={{ md: 4 }}
                          LabelsGridProps={{ md: 8 }}
                        />
                    </ThemeProvider>
                </Box> 
                <Box className='button-box-position'>
                    <div className='button-position'>
                        <Button className='form-button' onClick={handleOpen} variant="contained">
                            <span className='button-text'>
                                UPDATE
                            </span> 
                        </Button>
                        <Button className='form-button' onClick={() => {handleDelete()}} variant="contained">
                            <span className='button-text'>
                                DELETE
                            </span>  
                        </Button>
                    </div>
                </Box> 
            </Paper>     
            <Box>
                <div className='button-position'>
                    <Button sx={{ marginBottom:'1rem'}} className='form-button' variant="contained" component={Link} to="/faceprint-mgt/view" startIcon={<ArrowBackIcon />}>
                        <span className='button-text'>
                            BACK
                        </span> 
                    </Button> 
                </div>
            </Box>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openDeleteAlert}> 
                {redirect} 
            </Snackbar>
            { openDeleteAlert ?  
                <Box sx={{ width: '100%' }}>
                  <LinearProgress variant="determinate" color="success" value={progress} />
                </Box>
                :
                <></>
            }
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openAlert}> 
                {alert} 
            </Snackbar>
        </Box>    
    );
};   